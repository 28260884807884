<template>
	<div class="container">
		<div class="content">
			<h3 class="title">{{newsInfo.title}}</h3>		
			<div class="publish-info flex flex-center">
				<div class="publish-items" v-if="type == 'sx'">发布时间：{{newsInfo.update_time | formatDate}}</div>
				<div class="publish-items" v-else>发布时间：{{newsInfo.create_time | formatDate}}</div>
				<div class="publish-items">浏览：{{newsInfo.views}}</div>
			</div>	
			<div class="main" v-html="newsInfo.content"></div>
		</div>
	</div>
</template>

<script>
	import {formatDate} from '@/utils/timeStamp.js'
	export default {
		data() {
			return {
				newsInfo:{},
				type:this.$route.query.type
			};
		},
		created() {
			//子路由接收参数
			let id = this.$route.query.id;
			this.getNewsInfo(id,this.type)
		},
		filters: {
			formatDate(time) {
				time = time * 1000
				let date = new Date(time)
				return formatDate(date, 'yyyy-MM-dd')
			}
		},	
		methods:{
			getNewsInfo(id,type) {
				if (type == "sx") {
					this.$http.get('api/web/news/getNewsInfo?article_id=' + id)
						.then((res) => {
							this.newsInfo = res.data.newsInfo
					})
				} else {
					this.$http.get('/api/web/news/getSchoolNewsInfo?id=' + id)
						.then((res) => {
							this.newsInfo = res.data.newsInfo
					})
				}
				
			}
		}
	};
	
</script>

<style lang='less' scoped>
	.container{
		width: 100%;
		padding: 30px 0;
		border-radius: 10px;
		.content{
			width: var(--container-width);
			margin: 0 auto;
			padding: 30px;
			background-color: white;
			.title{
				width: 100%;
				font-size: 24px;
				font-weight: bold;
				color: #333333;
				line-height: 36px;
				margin: 0 auto 0;
			}
			.publish-info{
				padding: 20px 0;
				margin-bottom: 50px;
				border-bottom: 2px solid #f5f5f5;
				color: #999;
				.publish-items{
					font-size: 15px;
					color: #666666;
					line-height: 32px;
					margin: 0 20px;
				}
			}
			p{
				font-size: 16px;
				color: #666666;
				line-height: 32px;
				margin: 0;
				margin-bottom: 30px;
				text-align: left;
				text-indent: 32px;
				img{
					display: block;
					margin: 0 auto 30px;
					max-width: var(--container-width);
				}
			}
		}
	}
</style>
